import MCDatepicker from 'mc-datepicker'

require('./grid-gallery.min.js')
window.Swal = require('sweetalert2')

const loader = document.querySelector('#loader')
const welcome = document.querySelector('#welcome')
const welcome_close = document.querySelector('#welcome button')
const header = document.querySelectorAll('.accordion-header')
const modal = document.querySelectorAll('[id^=modal]')
const modal_open = document.querySelectorAll('[id^=open-modal]')
const modal_close = document.querySelectorAll('[id^=close-modal]')
const booking_open = document.querySelectorAll('.open-booking')
const booking_close = document.querySelectorAll('.close-booking')
const section = document.querySelectorAll('section')
const location = document.querySelectorAll('[id^=location]')
const villa = location[0]?.closest('.dropdown').querySelectorAll('li[data-villa]')
const guest_adult = document.querySelectorAll('[id^=adult-guest]')
const guest_kid = document.querySelectorAll('[id^=kid-guest]')
const calendar = document.querySelectorAll('[id^=calendar]')
const input_group = document.querySelectorAll('.input-group')
const tomorrow = new Date(new Date().setHours(0,0,0,0) + 86400000) // +1 day
const overmorrow = new Date(tomorrow.getTime() + 86400000) // +2 days
const checkin = document.querySelectorAll('.checkin')
const checkout = document.querySelectorAll('.checkout')
const lang = document.querySelector('html').lang == 'ms' ? 'ms-MY' : 'en-US'
const weekday = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']

var _checkin = []
var _checkout = []

// Loader event listener
if(loader) {
    document.onreadystatechange = function () {
        if (document.readyState === "complete") {
            loader.classList.add('duration-700','transition-all')
            setTimeout(() => {
                loader.classList.add('!h-0','-top-20')
                setTimeout(() => {
                    loader.remove()
                }, 500);
            }, 0);
        }
    }
}

// Set session to show welcome per session
if(welcome_close) {
    welcome_close.onclick = () => {
        welcome.classList.add('animate-[fade-out_.7s_ease]')
        welcome.querySelector('.wrapper').classList.remove('animate-[zoom-in_.7s_ease]')
        welcome.querySelector('.wrapper').classList.add('animate-[zoom-out_.7s_ease]')
        setTimeout(() => {
            welcome.remove()
        }, 700);
        sessionStorage.setItem('welcomePopup', true);
    }
    if ('welcomePopup' in sessionStorage) {
        welcome.remove()
    }
}

// Datepicker for checkin & checkout
// Generate months array based on locale
window.dataMonths = (code) => {
    let months = []
    for (let i=0; i<12; i++) {
        months.push((new Date(new Date().getFullYear(), i, 1)).toLocaleString(code, {month: "long"}));
    }
    return months
}
// Init checkin datepicker for mobile & desktop
for(let i=0; i<checkin.length; i++) {
    _checkin[i] = MCDatepicker.create({
        el: '#'+checkin[i].id,
        dateFormat: 'dd/mm/yyyy',
        firstWeekday: 1,
        customWeekDays: lang == 'ms-MY' ? ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', 'Jumaat', 'Sabtu'] : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        customMonths: window.dataMonths(lang),
        customOkBTN: checkin[i].getAttribute('data-lang-ok'),
        customCancelBTN: checkin[i].getAttribute('data-lang-cancel'),
        customClearBTN: '',
        minDate: tomorrow,
        selectedDate: tomorrow
    })

    checkin[i].value = tomorrow.toLocaleDateString('ms-MY',{year: 'numeric', month: '2-digit', day: '2-digit'})
    checkin[i].onclick = () => _checkin[i].open()
}
// Init checkout datepicker for mobile & desktop
for(let i=0; i<checkout.length; i++) {
    _checkout[i] = MCDatepicker.create({
        el: '#'+checkout[i].id,
        dateFormat: 'dd/mm/yyyy',
        firstWeekday: 1,
        customWeekDays: lang == 'ms-MY' ? ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', 'Jumaat', 'Sabtu'] : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        customMonths: window.dataMonths(lang),
        customOkBTN: checkout[i].getAttribute('data-lang-ok'),
        customCancelBTN: checkout[i].getAttribute('data-lang-cancel'),
        customClearBTN: '',
        minDate: overmorrow,
        selectedDate: overmorrow
    })

    checkout[i].value = overmorrow.toLocaleDateString('ms-MY',{year: 'numeric', month: '2-digit', day: '2-digit'})
    checkout[i].onclick = () => _checkout[i].open()
}
// Update checkout date +1 day if selected checkin after than checkout date
for(let i=0; i<_checkout.length; i++) {
    _checkout[i].onSelect((date, formatedDate) => {
        if(date <= _checkin[i].getFullDate()) {
            let _yesterday = new Date(date.getTime() - 86400000)
            _checkin[i].setFullDate(_yesterday)
        }
    })
}
// Update checkin date -1 day if selected checkout before than checkin date
for(let i=0; i<_checkin.length; i++) {
    _checkin[i].onSelect((date, formatedDate) => {
        if(date >= _checkout[i].getFullDate()) {
            let _overmorrow = new Date(date.getTime() + 86400000)
            _checkout[i].setFullDate(_overmorrow)
        }
    })
}

// Active section event listener
for(let i=0; i<section.length; i++) {
    if(section[i].getAttribute('data-villa') != 'VillaPadu Dusun') {
        section[i].onclick = (ev) => {
            for(let j=0; j<section.length; j++) {
                if(section[i] != section[j] && section[j].classList.contains('active')) {
                    section[j].classList.remove('active')
                    if(ev.target.tagName.toLowerCase() !== 'button') {
                        section[j].querySelector('.toggle-content').style.height = '0'
                    }
                }
            }

            section[i].classList.add('active')

            // Set villa in select for current view
            for(let j=0; j<location.length; j++) {
                location[j].value = section[i].getAttribute('data-villa')
                location[j].closest('.dropdown').querySelector('span').innerHTML = section[i].getAttribute('data-villa')
            }

            // Close any opened modal other than current
            for(let j=0; j<modal.length; j++) {
                if(section[i] != section[j] && modal[j].classList.contains('active')) {
                    modal[j].classList.remove('active')
                }
            }

            changeGuest()
        }
    }
}

// Section modal event listener
for(let i=0; i<modal_open.length; i++) {
    modal_open[i].onclick = () => {
        modal[i].style.height = '100%'
        modal[i].classList.add('active');
        console.log(modal[i])
    }
}
for(let i=0; i<modal_close.length; i++) {
    modal_close[i].onclick = () => {
        modal[i].style.height = '0';
        modal[i].classList.remove('active');
    }
}

// Open booking modal event listener
for(let i=0; i<booking_open.length; i++) {
    booking_open[i].onclick = () => {
        document.getElementById('bookingMobile').style.height = '100%'
    }
}

// Close booking modal
for(let i=0; i<booking_close.length; i++) {
    booking_close[i].onclick = () => {
        document.getElementById('bookingMobile').style.height = '0'
    }
}

// Update location input after click dropdown
for(let i=0; i<location.length; i++) {
    let _villa = location[i].closest('.dropdown').querySelectorAll('li[data-villa]')

    for(let j=0; j<_villa.length; j++) {
        _villa[j].onclick = () => {
            location[i].value = _villa[j].getAttribute('data-villa');
            location[i].closest('.dropdown').querySelector('span').innerHTML = _villa[j].getAttribute('data-villa');
            changeGuest();
        }
    }
}

// Change guest dropdown element
window.changeGuest = () => {
    for(let i=0; i<location.length; i++) {
        for(let j=0; j<villa.length; j++) {
            if( location[i].value == villa[j].getAttribute('data-villa')) {
                let guests_adult = Number(villa[j].getAttribute('data-guest-adult'))
                let guests_kid = Number(villa[j].getAttribute('data-guest-kid'))
                let  _container_adult = guest_adult[i].closest('.dropdown.guest-adult').querySelector('ul')
                let  _container_kid = guest_kid[i].closest('.dropdown.guest-kid').querySelector('ul')

                guest_adult[i].value = 1
                guest_kid[i].value = 0
                guest_adult[i].closest('.dropdown.guest-adult').querySelector('span').innerHTML = '1 '+ checkin[i].getAttribute('data-lang-pax')
                guest_kid[i].closest('.dropdown.guest-kid').querySelector('span').innerHTML = '0 '+ checkin[i].getAttribute('data-lang-pax')
                _container_adult.innerHTML = ''
                _container_kid.innerHTML = ''
                for(let m=1; m<=guests_adult; m++) {
                    let _li = document.createElement('li')
                    _li.onclick = () => {
                        guest_adult[i].value = Number(m)
                        guest_adult[i].closest('.dropdown.guest-adult').querySelector('span').innerHTML = m +' '+ checkin[i].getAttribute('data-lang-pax')
                    }
                    _li.innerHTML = m +' '+ checkin[i].getAttribute('data-lang-pax')
                    _container_adult.appendChild(_li)
                }
                for(let m=0; m<guests_kid; m++) {
                    let _li = document.createElement('li')
                    _li.onclick = () => {
                        guest_kid[i].value = Number(m+1)
                        guest_kid[i].closest('.dropdown.guest-kid').querySelector('span').innerHTML = (Number(m) + 1) +' '+ checkin[i].getAttribute('data-lang-pax')
                    }
                    _li.innerHTML = (Number(m)+1) +' '+ checkin[i].getAttribute('data-lang-pax')
                    _container_kid.appendChild(_li)
                }
            }
        }
    }
}

// Init guest option
changeGuest()

// Location dropdown event listener
for(let i=0; i<location.length; i++) {
    location[i].onchange = () => changeGuest
}

// Modal accordion event listener
for(let i=0; i<header.length; i++){
    header[i].onclick = (ev) => {
        let _header = ev.target.closest('.accordion-header')
        let _content = _header.nextElementSibling

        // Close other opened accordion
        for(let j=0; j<header.length; j++) {
            if(header[i] != header[j]) {
                let __content = header[j].nextElementSibling
                if(__content) {
                    __content.style.maxHeight = `${__content.scrollHeight}px`
                    setTimeout(() => {
                        __content.style.maxHeight = `0px`
                    }, 10)

                    if(header[j].classList.contains('active')) {
                        header[j].classList.remove('active')
                    }
                }
            }
        }

        // Open clicked accordion
        if(_content) {
            let accordionMaxHeight = _content.style.maxHeight

            if (accordionMaxHeight == "0px" || accordionMaxHeight.length == 0) {
                _content.style.maxHeight = `${_content.scrollHeight}px`
                setTimeout(() => {
                    _content.style.maxHeight = `max-content`
                }, 500)

                header[i].classList.add('active')
            } else {
                _content.style.maxHeight = `${_content.scrollHeight}px`
                setTimeout(() => {
                    _content.style.maxHeight = `0px`
                }, 10)

                if(header[i].classList.contains('active')) {
                    header[i].classList.remove('active')
                }
            }
        }
    }
}

// Trigger closest input/select when clicked icon/label
for(let i=0; i<input_group.length; i++) {
    input_group[i].onclick = (ev) => {

        // Simulate element click if input exist
        input_group[i].querySelector('input')?.click()

        // Toggle checked for checkbox input
        if(input_group[i].querySelector('[type=checkbox]')) {
            input_group[i].querySelector('[type=checkbox]').checked = !input_group[i].querySelector('[type=checkbox]').checked
        }
    }
}

// Generate calendar structure
for(let i=0; i<calendar.length; i++) {
    let month = calendar[i].querySelectorAll('ul')
    let booked = JSON.parse(calendar[i].getAttribute('data-date-booked'))
    let holiday = JSON.parse(calendar[i].getAttribute('data-date-holiday'))

    for(let j=0; j<month.length; j++) {
        let last_day = new Date()
        let _year = month[j].getAttribute('data-year')
        let _month = month[j].getAttribute('data-month')

        last_day.setYear(_year)
        last_day.setMonth(_month)
        last_day.setDate(0)

        for(let k=1; k<last_day.getDate()+1; k++){
            let date = _year+"-"+_month+"-"+k
            let d = new Date(date)
            let _li = document.createElement('li')
            _li.setAttribute('data-day', d.getDay())
            _li.setAttribute('data-date', k)
            _li.setAttribute('data-type', booked.includes(date) ? 0 : (holiday.includes(date) ? 2 : 1)) // 0 - booked, 1 - available, 2 - holiday
            _li.setAttribute('data-label', booked.includes(date) ? calendar[i].getAttribute('data-lang-booked') : 'RM '+ (holiday.includes(date) ? Number(calendar[i].getAttribute('data-price-holiday')).toLocaleString(lang) : Number(calendar[i].getAttribute('data-price-'+weekday[d.getDay()])).toLocaleString(lang)))
            month[j].appendChild(_li)
        }
    }
}

// Countdown
const countdown = document.querySelectorAll(".countdown")
for(let i=0; i<countdown.length; i++) {
    var countDownDate = new Date(countdown[i].getAttribute('data-expired')).getTime();
    var x = setInterval(function() {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        countdown[i].innerHTML = minutes + "m " + seconds + "s ";

        if (distance < 0) {
            clearInterval(x);
            countdown[i].innerHTML = countdown[i].getAttribute('data-label');
        }
    }, 1000);
}

// Terms & Conditions modal
const form = document.querySelector('#checkout')
if(form) {
    form.onclick = (e) => {
        new Swal({
            title: form.getAttribute('data-title'),
            html:  form.getAttribute('data-html'),
            confirmButtonText: form.getAttribute('data-confirm'),
        }).then((result) => {
            if (result.isConfirmed){
                document.querySelector('form').submit()
            }
        })
    }
}

// Sidebar
const sidebarClose = document.querySelectorAll('.close-sidebar')
for(let i=0; i<sidebarClose.length; i++) {
    sidebarClose[i].onclick = (e) => {
        if(e.target.classList.contains('close-sidebar')) {
            let _nav = document.querySelector('nav')
            let _sidebar = document.querySelector('nav > div')
            _nav.classList.remove('!opacity-100')
            setTimeout(()=> _nav.classList.add('!hidden'), 520)
            _sidebar.classList.remove('w-full','md:w-[350px]')
            _sidebar.classList.add('w-0')
        }
    }
}
const sidebarOpen = document.querySelectorAll('.open-sidebar')
for(let i=0; i<sidebarOpen.length; i++) {
    sidebarOpen[i].onclick = () => {
        let _nav = document.querySelector('nav')
        let _sidebar = document.querySelector('nav > div')
        _nav.classList.add('!opacity-100')
        _nav.classList.remove('!hidden')
        setTimeout(() => {
            _sidebar.classList.add('w-full','md:w-[350px]')
            _sidebar.classList.remove('w-0')
        }, 20)
    }
}
const pageClose = document.querySelectorAll('.close-page')
for(let i=0; i<pageClose.length; i++) {
    pageClose[i].onclick = () => {
        let _page = pageClose[i].closest('[id^=page-]')
        let _wrapper = _page.querySelector('.wrapper')
        _wrapper.classList.remove('animate-[zoom-in_.7s_ease]')
        _wrapper.classList.add('animate-[zoom-out_.7s_ease]')
        _page.classList.add('animate-[fade-out_.7s_ease]')
        _page.classList.remove('animate-[fade-in_.7s_ease]')
        setTimeout(()=>{
            _page.classList.add('hidden')
        }, 720)
    }
}
const pageOpen = document.querySelectorAll('.open-page')
for(let i=0; i<pageOpen.length; i++) {
    pageOpen[i].onclick = () => {
        for(let j=0; j<pageClose.length; j++) {
            let __page = pageClose[j].closest('[id^=page-]')
            let __wrapper = __page.querySelector('.wrapper')
            __wrapper.classList.remove('animate-[zoom-in_.7s_ease]')
            __wrapper.classList.add('animate-[zoom-out_.7s_ease]')
            __page.classList.add('animate-[fade-out_.7s_ease]')
            __page.classList.remove('animate-[fade-in_.7s_ease]')
            setTimeout(()=>{
                __page.classList.add('hidden')
            }, 720)
        }

        setTimeout(() => {
            let _page = document.getElementById(pageOpen[i].getAttribute('data-modal'))
            let _wrapper = _page.querySelector('.wrapper')
            _page.classList.remove('hidden')
            _page.classList.add('animate-[fade-in_.7s_ease]')
            _page.classList.remove('animate-[fade-out_.7s_ease]')
            _wrapper.classList.add('animate-[zoom-in_.7s_ease]')
            _wrapper.classList.remove('animate-[zoom-out_.7s_ease]')
        }, 720)
    }
}
